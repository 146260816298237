<template>
  <div class="myProduction subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>我的商品</p>
      </div>
    </div>
    <el-table :data="tableData" border>
      <el-table-column type="index" label="序号" width="80"></el-table-column>
      <el-table-column prop="name" label="商品"></el-table-column>
      <el-table-column prop="quantity" label="数量"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="viewDetails(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag title="商品详情" :visible.sync="dialogVisible" width="670px">
      <div class="itemDetails">
        <el-row>
          <el-col :span="4">
            <span class="listTitle">商品分类：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.name }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">价格：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.price }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.quantity }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">买卖方向：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.bsFlagName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">银行名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.bankName }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">银行卡号：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.bankNumber }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">仓库：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.warehouse }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">计量单位：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ itemDetails.meterageUnit }}</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 10,
      totalPage: null,
      itemDetails: {},
      dialogVisible: false
    };
  },
  mounted() {
    // this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryTradeNote.param.page = this.current - 1;
      protocolFwd.param_queryTradeNote.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryTradeNote).then(response => {
        const datas = response.data.content;
        for (let i = 0; i < datas.length; i++) {}
        this.tableData = response.data.content;
        this.totalPage = response.data.totalElements;
      });
    },
    viewDetails(item) {
      this.itemDetails = item;
      this.dialogVisible = true;
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPage .subPageTitle {
  overflow: hidden;
}
.subPage .subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPage .subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.itemDetails {
  line-height: 35px;
}
.itemDetails .listTitle {
  display: block;
  text-align: right;
}
.itemDetails .listContent {
  margin-left: 5px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
